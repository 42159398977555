import { SearchBar, PageSortFilter } from "components/shared";
import { LevelSelector } from "./level-selector";

const ContentMenu = () => {
  return (
    <div className="flex flex-col items-end gap-28">
      <div className="flex items-end justify-between w-full">
        <LevelSelector />
        <SearchBar />
      </div>
      <div className="flex justify-between w-full">
        <span className="font-medium">
          문제 <span className="font-semibold">200</span>개
        </span>
        <PageSortFilter sortOptions={SORT_OPTIONS} />
      </div>
    </div>
  );
};

export const SORT_OPTIONS = [
  {
    name: "최신순",
    query: "recent",
  },
  {
    name: "정답률 높은순",
    query: "clear_desc",
  },
  {
    name: "정답률 낮은순",
    query: "clear_asc",
  },
];

export default ContentMenu;
