import { type BEGINNER_PROBLEMS } from "dummy-data";
import { useNavigate } from "react-router-dom";

interface Props {
  problems: typeof BEGINNER_PROBLEMS;
}

export const ProblemTable = ({ problems }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="rounded-[4px] border-1">
      <table className="w-full">
        <thead className="font-medium border-b">
          <tr className="under:py-16 under:px-12 text-14 under:text-[#446879]">
            <th className="text-left w-120">문제 번호</th>
            <th>문제 제목</th>
            <th className="w-80">난이도</th>
            <th className="text-right w-100">맞힌 사람</th>
            <th className="text-right w-100">제출 횟수</th>
            <th className="text-right w-80">정답률</th>
          </tr>
        </thead>
        <tbody>
          {problems.map(
            ({ id, name, level, accepted, acceptedRate, submission }) => {
              return (
                <tr
                  key={id}
                  className="font-medium border-b last:border-none under:py-16 under:px-12 text-14"
                >
                  <td>{id}</td>
                  <td
                    onClick={() => navigate(`/problem/${id}`)}
                    className="cursor-pointer"
                  >
                    {name}
                  </td>
                  <td className="font-semibold text-center">Lv. {level}</td>
                  <td className="text-right">{accepted}명</td>
                  <td className="text-right">{submission}</td>
                  <td className="text-right">{acceptedRate}%</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};
