import { BEGINNER_PROBLEMS } from "dummy-data";
import { Pagination, ProblemTable } from "components/shared";
import ContentMenu from "./menu";

export const ProblemContent = () => {
  return (
    <section className="flex flex-col gap-16 px-20 mx-auto mt-80 max-w-1200">
      <ContentMenu />
      <ProblemTable problems={BEGINNER_PROBLEMS} />
      <Pagination totalPage={20} />
    </section>
  );
};
